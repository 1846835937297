import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import logo from './logo.svg';
import LandingPage from './components/LandingPage';
import Header from './components/Header';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Header/>
        <div>
          <Routes>
            <Route path="/" element={ <LandingPage /> }></Route>
            <Route path="/foobar" element={ <Navigate to="/" /> }></Route>
            <Route path="/nonauthenticated" element={ 
              <>Please login using the "Authenticate" button</>
            }></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
